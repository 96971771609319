const en = JSON.stringify({
    lan: 'us',
    header: {
        navMenuItem1: {
            content: 'Home',
        },
        navMenuItem2: {
            content: 'About us',
        },
        navMenuItem3: {
            content: 'Services',
        },
        linkToHome: {
            content: '/en'
        },
        navMenuService1: {
            content: 'Sea service'
        },
        navMenuService2: {
            content: 'Air service'
        },
        navMenuService3: {
            content: 'Land service'
        }
    },
    footer: {
        poweredBy: {
            content: 'Powered by'
        },
        nezzed: {
            content: 'Nezzed IT Solutions'
        },
        copyright: {
            content: 'Copyright'
        },
        reserved: {
            content: 'reserved Seafly Logistics LLC'
        }
    },
    slogan: {
        content: 'By Air, Sea And Land'
    },
    about: {
        content: 'We believe that every small or medium business deserves the opportunity to go from local to global. Let us simplify your logistics through our intuitive platform now.'
    },
    servicesTitle: {
        content: 'Services'
    },
    cardServiceText1: {
        content: 'We transport maritime cargo to and from the most important ports in the world, simplifying logistics operations for our clients.'
    },
    cardServiceText2: {
        content: 'A simple, fast and competitive service in alliance with the most important airlines make the difference when choosing us.'
    },
    cardServiceText3: {
        content: 'We transport maritime cargo to and from the most important ports in the world, simplifying logistics operations for our clients.'
    },
    cardServiceTitle1: {
        content: 'Sea'
    },
    cardServiceTitle2: {
        content: 'Air'
    },
    cardServiceTitle3: {
        content: 'Land'
    },
    customBrokerTitle: {
        content: 'CUSTOMS BROKERS'
    },
    customBrokerText: {
        content: `
        Our CUSTOMS Division is made up of experienced young professionals, who will 
        be able to attend each operation in a personalized way, managing each load, 
        providing legal and operational advice for your import or export of Merchandise.
        • Processing and officialization of import and export clearances.
        • Preparation, presentation of the respective customs documentation.
        • Customs advice and tariff classification of merchandise of any kind.
        • Records of temporary admission of machines and equipment.
        • Temporary import dispatches, follow-up, control, management of extensions and 
        re-export processing. Nationalization.
        `
    },
    btnText: {
        content: 'Contact us'
    }
})

export default en