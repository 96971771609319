import { useEffect, useState } from "react"

const Footer = ({ translate }) => {
    const [hostname, setHostname] = useState('')

    useEffect(() => setHostname(window.location.hostname))

    return (
        <footer className='row'>
            <article className="col-12 col-sm-12 col-lg-12 col-xl-12 contain-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.977696949571!2d-80.19212138548531!3d25.771300214493987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b69d071f94d5%3A0x3daf9d2c5631763a!2sRegus%20-%20Florida%2C%20Miami%20-%20Wells%20Fargo%20Plaza!5e0!3m2!1ses-419!2sar!4v1659364729106!5m2!1ses-419!2sar"
                />
            </article>
            <article className="col-12 col-sm-12 col-lg-12 col-xl-12">
                <ul>
                    <li>{translate?.copyright?.content} &copy; {translate?.reserved?.content} - {hostname} {(new Date()).getFullYear()}</li>
                    <li><a className="text-light" href="tel:7866091354">Tel: 7866091354</a></li>
                    <li>Miami Branch - New Jersey Branch</li>
                    <li>
                        <a className="text-light" href="mailto:contact@seaflylogisticsllcusa.com">
                           contact@seaflylogisticsllcusa.com
                        </a>
                    </li>
                    <li>
                        {translate?.poweredBy?.content} <a
                            href="https://instagram.com/nezzedsolutionsit"
                            target='_blank'
                            className='text-light'>
                            {translate?.nezzed?.content}
                        </a>
                    </li>
                </ul>
            </article>
        </footer>
    )
}

export default Footer