import Body from './gadgets/_body'
import Carousel from './carousel.component'
import Card from './card.component'
import CardModule from './cardModule.component'
import SloganLegend from './sloganLegend.component'
import Spacing from './spacing.component'
import SwipeUp from './arrowAnimated.component'
import About from './about.component'

const PWAComponent = ({ translate }) => {
    return (
        <Body headProps={{ title: 'Seafly Logistics LLC' }}>
            <article className="row contain">
                <article className="card-contain col-12 col-sm-12 col-lg-12 col-xl-12 p-0">
                    <Carousel
                        img1='img/service1.jpg'
                        img2='img/service2.jpg'
                        img3='img/service3.jpg'
                    />
                </article>
                <SloganLegend sloganData={translate?.slogan?.content} />
                <About aboutData={{
                    title: <img src='img/brand.png' className='brand-aboutus' />,
                    text: translate?.about?.content,
                    idReferenceHash: 'nosotros'
                }} />
                <SwipeUp />
                <article className="col-12 titlePlans">
                    <h4>{translate?.servicesTitle?.content}</h4>
                </article>
                <Card
                    cardData={{
                        title: translate?.cardServiceTitle1?.content,
                        description: translate?.cardServiceText1?.content,
                        img: 'img/service1.jpg',
                        idService: 'service1'
                    }}
                />
                <Card
                    cardData={{
                        title: translate?.cardServiceTitle2?.content,
                        description: translate?.cardServiceText2?.content,
                        img: 'img/service2.jpg',
                        idService: 'service2'
                    }}
                />
                <Card
                    cardData={{
                        title: translate?.cardServiceTitle3?.content,
                        description: translate?.cardServiceText3?.content,
                        img: 'img/service3.jpg',
                        idService: 'service3'
                    }}
                />
                <Spacing spacing='20vh' />
                <CardModule cardData={{
                    title: translate?.cardServiceTitle1?.content,
                    text: translate?.cardServiceText1?.content,
                    img: 'img/service1.jpg',
                    idReferenceHash: 'service1',
                    btnText: translate?.btnText?.content
                }} />
                <CardModule cardData={{
                    title: translate?.cardServiceTitle2?.content,
                    text: translate?.cardServiceText2?.content,
                    img: 'img/service2.jpg',
                    idReferenceHash: 'service2',
                    btnText: translate?.btnText?.content
                }} />
                <CardModule cardData={{
                    title: translate?.cardServiceTitle3?.content,
                    text: translate?.cardServiceText3?.content,
                    img: 'img/service3.jpg',
                    idReferenceHash: 'service3',
                    btnText: translate?.btnText?.content
                }} />
                <article className="col-12 mt-5 mb-5">
                    <About aboutData={{
                        title: translate?.customBrokerTitle?.content,
                        text: translate?.customBrokerText?.content,
                        idReferenceHash: 'nosotros'
                    }} />
                </article>
            </article>
        </Body>
    )
}

export default PWAComponent