import Header from './_header'
import Footer from './_footer'
import lanDetect from '../translate'

const Body = ({ children, headProps, urlNavigation }) => {
    const lan = lanDetect()

    return(
        <>
        <main className="container-fluid">
            <Header lan={lan?.lan} translate={lan?.header}/>
            { children }
            <Footer translate={lan?.footer}/>
        </main>
        </>
    )
}

export default Body