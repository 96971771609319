import en from "./en" 
import es from "./es"
import br from "./br"

const lanDetect = () => {
    const lan = {
        en: en,
        es: es,
        br: br,
    }

    const lanURL = window.location.pathname.replace('/', '')
    const defaultLan = 'en'

    return JSON.parse(lan[lanURL] || lan[defaultLan])
}

export default lanDetect