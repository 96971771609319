const es = JSON.stringify({
    lan: 'es',
    header: {
        navMenuItem1: {
            content: 'Inicio',
        },
        navMenuItem2: {
            content: 'Sobre nosotros',
        },
        navMenuItem3: {
            content: 'Servicios',
        },
        linkToHome: {
            content: '/es'
        },
        navMenuService1: {
            content: 'Servicio marítimo'
        },
        navMenuService2: {
            content: 'Servicio aéreo'
        },
        navMenuService3: {
            content: 'Servicio terrestre'
        }
    },
    footer: {
        poweredBy: {
            content: 'Impulsado por'
        },
        nezzed: {
            content: 'Nezzed IT Solutions'
        },
        copyright: {
            content: 'Derechos de autor'
        },
        reserved: {
            content: 'reservado Seafly Logistics LLC'
        }
    },
    slogan: {
        content: 'Por Aire, Mar Y Tierra'
    },
    about: {
        content: 'Creemos que toda pequeña o mediana empresa merece la oportunidad de pasar de lo local a lo global. Permítanos simplificar su logística a través de nuestra plataforma intuitiva ahora.'
    },
    servicesTitle: {
        content: 'Servicios'
    },
    cardServiceText1: {
        content: 'Transportamos carga marítima desde y hacia los más importantes puertos del mundo, simplificando las operaciones logísticas para nuestros clientes.'
    },
    cardServiceText2: {
        content: 'Un servicio sencillo, rápido y competitivo en alianza con las aerolíneas más importantes marca la diferencia al elegirnos.'
    },
    cardServiceText3: {
        content: 'Movemos carga en todo el Mundo a través de nuestra flota terrestre, servicio de vigilancia más gestión aduanera.'
    },
    cardServiceTitle1: {
        content: 'Mar'
    },
    cardServiceTitle2: {
        content: 'Aire'
    },
    cardServiceTitle3: {
        content: 'Tierra'
    },
    customBrokerTitle: {
        content: 'AGENTES DE ADUANAS'
    },
    customBrokerText: {
        content: `
        Nuestra División ADUANAS está formada por jóvenes profesionales con experiencia, que
        poder atender cada operación de forma personalizada, gestionando cada carga,
        brindando asesoría legal y operativa para su importación o exportación de Mercancías.
        • Tramitación y oficialización de despachos de importación y exportación.
        • Elaboración, presentación de la respectiva documentación aduanera.
        • Asesoramiento aduanero y clasificación arancelaria de mercancías de cualquier tipo.
        • Registros de admisión temporal de máquinas y equipos.
        • Despachos de importación temporal, seguimiento, control, gestión de prórrogas y
        procesamiento de reexportación. Nacionalización.
        `
    },
    btnText: {
        content: 'Contactanos'
    }
})

export default es