const br = JSON.stringify({
    lan: 'br',
    header: {
        navMenuItem1: {
            content: 'Começo',
        },
        navMenuItem2: {
            content: 'Sobre nós',
        },
        navMenuItem3: {
            content: 'Serviços',
        },
        linkToHome: {
            content: '/br'
        },
        navMenuService1: {
            content: 'Serviço marítimo'
        },
        navMenuService2: {
            content: 'Serviço ar'
        },
        navMenuService3: {
            content: 'Serviço terrestre'
        }
    },
    footer: {
        poweredBy: {
            content: 'Distribuído por'
        },
        nezzed: {
            content: 'Nezzed IT Solutions'
        },
        copyright: {
            content: 'Direitos autorais'
        },
        reserved: {
            content: 'reservado Seafly Logistics LLC'
        }
    },
    slogan: {
        content: 'Pelo ar, mar e terra'
    },
    about: {
        content: 'Acreditamos que toda pequena ou média empresa merece a oportunidade de passar do local ao global. Vamos simplificar a sua logística através da nossa plataforma intuitiva agora.'
    },
    servicesTitle: {
        content: 'Serviços'
    },
    cardServiceText1: {
        content: 'Transportamos cargas marítimas de e para os mais importantes portos do mundo, simplificando as operações logísticas paranossos clientes.'
    },
    cardServiceText2: {
        content: 'Um serviço simples, rápido e competitivo em aliança com as companhias aéreas mais importantes fazem a diferença ao nos escolher.'
    },
    cardServiceText3: {
        content: 'Movimentamos cargas em todo o mundo através de nossa frota terrestre, serviço de monitoramento mais gestão aduaneira.'
    },
    cardServiceTitle1: {
        content: 'Mar'
    },
    cardServiceTitle2: {
        content: 'Ar'
    },
    cardServiceTitle3: {
        content: 'Terra'
    },
    customBrokerTitle: {
        content: 'CORRETORES ADUANEIROS'
    },
    customBrokerText: {
        content: `
        A nossa Divisão ALFÂNDEGA é composta por jovens profissionais experientes, que irão
        poder atender cada operação de forma personalizada, gerenciando cada carga,
        prestando assessoria jurídica e operacional para sua importação ou exportação de Mercadorias.
        • Processamento e oficialização de despachos de importação e exportação.
        • Preparação, apresentação da respectiva documentação aduaneira.
        • Assessoria aduaneira e classificação tarifária de mercadorias de qualquer natureza.
        • Registros de admissão temporária de máquinas e equipamentos.
        • Despachos temporários de importação, acompanhamento, controle, gestão de prorrogações e
        processamento de reexportação. Nacionalização.
        `
    },
    btnText: {
        content: 'Contate-Nos'
    }
})

export default br